<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners" @open="onOpen" @close="onClose" title="匹配星广汇红人" :append-to-body="true">
      <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="120px">
        <el-row :gutter="15">
          <el-col :span="11">
            <el-form-item label="星象仪红人：">
              <template slot="label">
                <span style="font-weight: 800;margin-left: 10px">星象仪红人：</span>
              </template>
              <span style="">{{ this.formData.nickname || '--' }}</span>
              <br/>
              <ArtistSearch @handleSelect="chooseArtist"/>
            </el-form-item>
          </el-col>
          <el-col :span="2" style="text-align: center">
            <i class="el-icon-sort" style="font-size: 1.6rem"></i>
          </el-col>
          <el-col :span="11">
            <el-form-item label="星象仪红人：">
              <template slot="label">
                <span style="font-weight: 800;margin-left: 10px">星广汇红人：</span>
              </template>
              <span>{{ this.formData.ad_nickname || '--' }}</span>
              <br/>
              <!--              <AdArtistSearch @handleSelect="chooseAdArtist"/>-->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handelConfirm" v-if="!RawData.artist_id">绑定</el-button>
        <el-button type="warning" @click="cancelBind" v-else>解绑</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import AdArtistSearch from "@/components/artist/AdArtistSearch";
import ArtistSearch from "@/components/artist/ArtistSearch";

export default {
  name: "ArtistMatch",
  inheritAttrs: false,
  components: { AdArtistSearch, ArtistSearch },
  props: {
    RawData: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      formData: {
        artist_id: undefined,
        nickname: undefined,
        ad_nickname: undefined,
        ad_artist_id: undefined,
      },
      rules: {
        nickname: [{
          required: true,
          message: '请输入红人',
          trigger: 'blur'
        }],
        ad_nickname: [{
          required: true,
          message: '请输入匹配红人',
          trigger: 'blur'
        }],
      },
    }
  },
  computed: {},
  watch: {
    // RawData: {
    //   deep: true,
    //   handler() {
    //     this.formData = { ...this.RawData }
    //   }
    // }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    onOpen() {
      this.formData = { ...this.RawData }
      this.getInfo()
    },
    onClose() {
      this.$refs['elForm'].resetFields()
      this.$emit('dialogClose', this.formData)
    },
    close() {
      this.$emit('update:visible', false)
    },
    handelConfirm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return

        // this.close()
        this.saveArtistCompare(this.formData)
      })
    },
    async cancelBind() {
      let id = await this.$api.delArtistCompare({ artist_id: this.formData.artist_id })
      if(id){
        this.$notify.success('解绑成功');
        this.close()
      }
    },
    async getInfo() {
      if (this.id) {
        let { info } = await this.$api.getArtistInfo(this.id)
        if (info) {
          this.formData.artist_id = info.id
          this.formData.nickname = info.nickname
        } else {
          this.$notify.error('红人（KOL）不存在！！！');
        }
      }
    },
    chooseAdArtist(item) {
      if (item) {
        this.formData.ad_artist_id = item.id
        this.formData.ad_nickname = item.nickname
      } else {
        this.searchCondition.ad_artist_id = null
        this.searchCondition.ad_nickname = null

      }
    },
    chooseArtist(item) {
      if (item) {
        this.formData.artist_id = item.id
        this.formData.nickname = item.nickname
      } else {
        this.searchCondition.artist_id = null
        this.searchCondition.nickname = null

      }
    },
    async saveArtistCompare(row) {
      let id = await this.$api.saveArtistCompare({
        artist_id: row.artist_id,
        nickname: row.nickname,
        ad_artist_id: row.ad_artist_id,
        ad_nickname: row.ad_nickname
      })
      if (id) {
        this.$notify.success(`成功匹配：${ row.nickname } = ${ row.ad_nickname }`)
        this.close()
      }
    },
  }
}

</script>

<style scoped>

</style>

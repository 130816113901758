<template>
  <div style="margin-top: 5px">
    <!--        营收保量-->
    <AdIncome></AdIncome>

    <div style='display: none'>
      <el-row class="table-header">
        <!--          筛选条件-->
        <el-col :span="20">
          <el-form :inline="true" size="mini">

            <el-form-item label="">
              <el-date-picker
                v-model="searchCondition.ym"
                type="month"
                value-format="yyyy-MM"
                format="yyyy年MM月"
                placeholder="选择月份" style="width: 160px">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="initList">查询<i class="el-icon-refresh"></i></el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div class="default-table">
            <ApeTable ref="apeTable"
                      :data="dataList"
                      :columns="columns"
                      :loading="loadingStatus"
                      :pagingData="pagingData"
                      :summary-method="getSummaries"

                      highlight-current-row
                      show-summary
                      @row-contextmenu="openMenu"
                      border>
              <el-table-column label="序号" min-width="80" slot="first-column" align="center">
                <template slot-scope="{$index}">
                  {{ $index + 1 }}
                </template>
              </el-table-column>
              <el-table-column prop="nickname" label="红人昵称" min-width="180" align="center" show-overflow-tooltip
                               slot="first-column" sortable>
                <template slot-scope="scope">
                  <artist-nickname :info="scope.row"/>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="广告系统-红人昵称" min-width="120" align="center"
                               show-overflow-tooltip sortable></el-table-column>
              <el-table-column prop="order_money" label="订单金额（元）" min-width="120" align="center"
                               show-overflow-tooltip sortable>
                <template slot-scope="scope">
                  {{ moneyFormat(scope.row.order_money) }}

                </template>
              </el-table-column>
              <el-table-column prop="order_count" label="订单数" min-width="120" align="center"
                               show-overflow-tooltip sortable></el-table-column>
              <!--            <el-table-column prop="order_exec_count" label="已执行单数" min-width="120" align="center"-->
              <!--                             show-overflow-tooltip sortable></el-table-column>-->
            </ApeTable>
            <el-card shadow="always" style="margin-top: 10px;text-align:right;width: 100%">
              <h3>合计金额：<span style="color: #ff3176;font-style:italic "> {{
                  $utils.numberFormat(order_money, 2)
                }}</span> ，合计订单数:<span
                style="color: #ff3176;font-style:italic "> {{ order_count || 0 }}</span>，平均金额:<span
                style="color: #ff3176;font-style:italic "> {{
                  order_count > 0 ? $utils.numberFormat(order_money / order_count, 2) : '/'
                }}</span></h3>
            </el-card>
          </div>
        </el-col>
        <!--      <el-col :span="6">-->
        <!--        <pre style="height: 100vh">-->
        <!--          {{ JSON.stringify(menuData, null, 4) }}-->
        <!--        </pre>-->

        <!--      </el-col>-->
      </el-row>
    </div>

    <!--    红人匹配   -->
    <ArtistMatch @dialogClose="dialogClose" :visible.sync="dialogArtistMatchVisible"
                 :raw-data="{ artist_id: formData.artist_id,nickname: formData.nickname,ad_nickname: formData.name,ad_artist_id: formData.ad_artist_id}"/>

    <!--     编辑、添加目标描述-->
    <Contextmenu :menu-data="menuData" @change="selectedMenu"/>
    <DetailsDrawer :drawerTitle="drawerTitle" :drawerTableData="drawerTableData"
                   :drawerTableTitle="drawerTableTitle"
                   :drawerVisible.sync="drawerVisible"></DetailsDrawer>

  </div>
</template>

<script>
import ApeTable from '@/components/ApeTable'
import ArtistNickname from '@/pages/artist/ArtistNickname'
import ArtistMatch from './ArtistMatch'
import Contextmenu from './Contextmenu'
import { mapGetters } from 'vuex'
import DetailsDrawer from '@/components/DetailsDrawer'
import AdIncome from '@/pages/revenue/order/AdIncome'

const { numberFormat } = require('@/utils/utils')

export default {
  name: 'RankTable',
  components: {
    AdIncome,
    ApeTable, ArtistNickname, ArtistMatch, Contextmenu, DetailsDrawer
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      searchCondition: { ym: null },
      loadingStatus: false,
      drawerVisible: false,
      dataList: [
        // {
        //   "artist_id": 23,
        //   "name": "一枝南南",
        //   "nickname": "一枝南南",
        //   "order_count": 27,
        //   "order_money": 3456200,
        //   "order_exec_count": 11
        // },
        // {
        //   "artist_id": 247,
        //   "name": "刘真",
        //   "nickname": "刘真",
        //   "order_count": 19,
        //   "order_money": 2145500,
        //   "order_exec_count": 13
        // },
        // {
        //   "artist_id": 187,
        //   "name": "黑心少奶奶",
        //   "nickname": "黑心少奶奶",
        //   "order_count": 22,
        //   "order_money": 1602525,
        //   "order_exec_count": 12
        // },
        // {
        //   "artist_id": 19,
        //   "name": "蒲儿姓蒲",
        //   "nickname": "蒲儿姓蒲",
        //   "order_count": 28,
        //   "order_money": 1591700,
        //   "order_exec_count": 14
        // },
      ],
      // 分页信息
      pagingData: {
        is_show: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        page_size: 20
      },
      columns: [],
      dialogArtistMatchVisible: false,
      formData: {},
      //start:鼠标右键菜单
      menuData: {
        visible: false,
        top: 0,
        left: 0,
        currentRow: {},
        currentColumn: {}
      },
      drawerTableData: [],
      drawerTitle: '',
      drawerTableTitle:'',
      order_money: 0,
      order_count: 0
    }
  },
  methods: {
    rateFormat(value) { // 金额 格式化
      return numberFormat(value, 2, '.', ',', 'round')
    },
    moneyFormat(value) { // 金额 格式化
      return numberFormat(value, 0, '.', ',', 'round')
    },
    numFormat(value) { // 数值 格式化
      return numberFormat(value, 0, '.', ',', 'round')
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (['order_money', 'order_count'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)

          } else {
            sums[index] = ''
          }
          switch (column.property) {
            case 'order_money':
              this.order_money = sums[index]
              sums[index] = numberFormat(sums[index], 2, '.', ',', 'round')
              break
            case 'order_count':
              this.order_count = sums[index]
              break
            default:
              // sums[index] = this.valFormat(sums[index])
              break
          }
        }
      })
      return sums
    },
    async initList() {
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()
      // console.log('s', searchCondition)
      if (searchCondition.year && searchCondition.month) {
        let { list } = await this.$api.getRevenueOrderStatistics(searchCondition)
        this.$nextTick(() => {
          this.dataList = list
        })
        this.loadingStatus = false
      }

    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}

      if (this.searchCondition.ym) {
        // console.log('ym',this.searchCondition.ym)
        let ym = this.searchCondition.ym
        let ymArr = ym.split('-')
        condition.year = parseInt(ymArr[0])
        condition.month = parseInt(ymArr[1])
      }

      return condition
    },
    defaultTime() {
      let date = new Date()
      //分页页面-默认上月
      date.setMonth(date.getMonth() - 1)
      let year = date.getFullYear() + ''
      let month = date.getMonth() + 1
      this.searchCondition.ym = `${year}-${month}`
    },
    //表格事件：行右键
    openMenu(row, column, e) {
      e.preventDefault()//阻止元素发生默认的行为
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      // const offsetTop = this.$el.getBoundingClientRect().top; // container margin left
      const offsetTop = this.$el.getBoundingClientRect().offsetTop // container margin left
      this.menuData = {
        visible: true,
        left: e.clientX - offsetLeft + 20,
        top: e.clientY + scrollTop + 10,
        offsetLeft: offsetLeft,
        offsetTop: offsetTop,
        scrollTop: scrollTop,
        currentRow: row
        // currentColumn: column
      }
      this.formData = { ...row }
      document.addEventListener('click', this.closeMenu) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
    },
    //右键菜单：设置是否月考核
    closeMenu(e) {
      //未点击在 菜单上，则关闭
      if (e.target.className.indexOf('contextmenu__item') === -1) {
        this.menuData.visible = false
        document.removeEventListener('click', this.closeMenu)
      }
    },
    // 选择菜单事件
    selectedMenu(menu) {
      switch (menu) {
        case 'bind_KOL':
          this.dialogArtistMatchVisible = true
          break
        case 'refresh':
          this.initList()
          break
        case 'checkDetails':
          this.checkDetails()
          break
        default:
          break
      }
    },
    async checkDetails() {
      // if (this.formData.ad_artist_id !== '') {
      let searchCondition = this.handleSearchCondition()
      searchCondition.artist_id = this.formData.ad_artist_id
      this.drawerTableData = await this.$api.getRevenueOrderDetails(searchCondition)
      this.drawerTitle = `${this.formData.nickname + '订单详情-' + searchCondition.year + '年' + searchCondition.month + '月'}`
      this.drawerTableTitle = `${searchCondition.year + '年' + searchCondition.month + '月【' + this.formData.nickname + '】订单详情'}`
      this.drawerVisible = true
      // } else this.$message.warning('未在系统比对到红人!')
    },
    dialogClose() {
      this.initList()
    }
  },
  created() {
    // this.defaultTime()
  }
}
</script>

<style scoped>
.transform {
  cursor: pointer;
  margin-left: 10px;
}

.transform:hover {
  color: red;
}
</style>

<template>
  <div>
    <el-autocomplete
        v-model="userName"
        :fetch-suggestions="querySearchAsync"
        placeholder="星广汇红人检索：输入关键字"
        clearable
        @select="handleSelect"
        @clear="clearSelect"
    >
    </el-autocomplete>
  </div>
</template>

<script>
export default {
  name: "AdArtistSearch",
  props: {
    name: {
      type: String,
      default() {
        return ''
      }
    },
    size: {
      type: String,
      default() {
        return 'mini'
      }
    }
  },
  data() {
    return {
      userName: this.name
    }
  },
  watch: {
    name: function (newVal, oldVal) {
      if (newVal !== oldVal)
        this.userName = newVal
    },
    userName: function (newVal, oldVal) {
      if (newVal !== oldVal)
        this.$emit('update:name', newVal)
    }
  },
  methods: {
    async querySearchAsync(queryString, cb) {
      if (this.userName) {
        let { list } = await this.$api.searchAdArtists({ nickname: this.userName })
        //数据处理
        list = list.map((item) => {
          item.value = item.nickname
          return item
        })

        cb(list)
      } else {
        cb([])
      }
    },
    // 处理搜索结果的选中
    handleSelect(item) {
      this.$emit('handleSelect', item)
    },
    clearSelect: function () {
      this.$emit('handleSelect', {})
    }
  }

}
</script>

<style scoped>

</style>

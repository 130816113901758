<template>
  <div id="menu-comm" style="padding: 20px">
    <div v-show="menuData.visible" :style="{left:menuData.left+'px',top:menuData.top+'px'}" class="menu">

      <div class="contextmenu__item" @click="selectEvent('bind_KOL')"
           v-if="userPermissions.indexOf('order_manage_bind') > -1">
        <el-row>
          <el-col :span="2">
            <i class="el-icon-plus"></i>
          </el-col>
          <el-col :span="20" style="text-align: left">
            绑定：【{{ menuData.currentRow.nickname || '--' }}《=》{{ menuData.currentRow.name }}】
          </el-col>
        </el-row>
      </div>
      <div class="contextmenu__item" @click="selectEvent('checkDetails')">
        <el-row>
          <el-col :span="2">
            <i class="el-icon-view"></i>
          </el-col>
          <el-col :span="20" style="text-align: left">
            &nbsp;查看订单详情
          </el-col>
        </el-row>
      </div>

      <div class="contextmenu__item" @click="selectEvent('refresh')">
        <el-row>
          <el-col :span="2">
            <i class="el-icon-refresh"></i>
          </el-col>
          <el-col :span="20" style="text-align: left">
            &nbsp;刷新列表
          </el-col>
        </el-row>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Contextmenu',
  computed: {
    ...mapGetters(['userPermissions'])
  },
  props: {
    menuData: {
      type: Object,
      default() {
        return {
          visible: false,
          top: 0,
          left: 0,
          currentRow: {},
          currentColumn: {}
        }
      }
    }
  },
  watch: {
    menuData: {
      deep: true,
      handler() {
        // this.initList()
      }
    }
  },
  data() {
    return {
      menuVisible: false
    }
  },
  methods: {
    closeMenu() {
      this.menuData.visible = false
    },
    selectEvent(val) {
      this.$emit('change', val)
      this.menuData.visible = false
    }
  }
}
</script>

<style scoped>

.contextmenu__item {
  display: block;
  line-height: 34px;
  text-align: center;
}

.contextmenu__item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.menu {
  position: absolute;
  background-color: #fff;
  width: 260px;
  /*height: 106px;*/
  font-size: 14px;
  color: #444040;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  white-space: nowrap;
  z-index: 1000;
  /*overflow-x: hidden;*/
  margin-left: 10px;
  padding: 10px;
}

.contextmenu__item:hover {
  cursor: pointer;
  background: #ff3176;
  border-color: #ff3176;
  color: #fff;
}
</style>

<template>
  <el-drawer
            :title="drawerTitle"
            :visible="drawerVisible"
            direction="rtl"
            @close="dialogClose"
            :size="'80%'"
    >
      <download-excel
              class="export-excel-wrapper"
              :data="drawerTableData"
              :fields="fields"
              :name="drawerTableTitle"
              type="xlsx"
      >
        <el-button type="primary" style="margin:0 0 5px 10px" @click="exportExcel">
          <i class="el-icon-download">导出</i>
        </el-button>
      </download-excel>
      <!--      订单编号，乙方公司，品牌，产品/内容，平台，内容（发布）形式，广告收入，执行时间，销售，执行人，订单类型，-->
      <el-table show-summary
                border
                :summary-method="summaries"
                :data="drawerTableData" ref="multipleTable">
        <el-table-column align="center" label="序号" type="index" width="80"></el-table-column>
        <el-table-column prop="order_no" align="center" label="订单编号"></el-table-column>
        <el-table-column prop="company.name" align="center" label="乙方公司"></el-table-column>
        <el-table-column prop="brand_info.name" align="center" label="品牌"></el-table-column>
        <el-table-column prop="product" align="center" label="产品/内容"></el-table-column>
        <el-table-column prop="platforms" align="center" label="平台"></el-table-column>
        <el-table-column prop="content_forms" align="center" label="内容（发布）形式"></el-table-column>
        <!--      <el-table-column prop="payment_account" align="center" label="含税收入"></el-table-column>-->
        <el-table-column prop="adv_revenue" align="center" label="广告收入"></el-table-column>
        <el-table-column prop="exec_timestamp_alias" align="center" label="执行时间"></el-table-column>
        <el-table-column prop="sale.name" align="center" label="销售"></el-table-column>
        <el-table-column prop="executor.name" align="center" label="执行人"></el-table-column>
        <el-table-column prop="order_type_alias" align="center" label="订单类型"></el-table-column>
      </el-table>
    </el-drawer>
</template>

<script>
import { numberFormat } from '@/utils/utils'

export default {
  name: 'DetailsDrawer',
  props: {
    drawerTitle: {
      type: String,
      default() {
        return ''
      }
    },
    drawerTableTitle: {
      type: String,
      default() {
        return ''
      }
    },
    drawerTableData: {
      type: Array,
      default() {
        return []
      }
    },
    drawerVisible: {
      type: Boolean,
      default() {
        return false
      }
    }

  },
  data(){
    return{
      fields:{
        '序号':'type',
        '订单编号':'order_no',
        '乙方公司':'company.name',
        '品牌':'brand_info.name',
        '产品/内容':'product',
        '平台':'platforms',
        '内容(发布)形式':'content_forms',
        '广告收入':'adv_revenue',
        '执行时间':'exec_timestamp_alias',
        '销售':'sale.name',
        '执行人':'executor.name',
        '订单类型':'order_type_alias'
      },
      excelData:[]
    }
  },
  methods: {
    summaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] = numberFormat(sums[index], 2, '.', ',', 'round')
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    dialogClose() {
      this.$emit('update:drawerVisible', false)
      // this.dialogData.visible = false
    },
    exportExcel(){
      for(let i in this.drawerTableData){
        this.drawerTableData[i].type = parseInt(i)+1
      }
      this.excelData = this.drawerTableData
    }

  }
}
</script>

<style scoped>

</style>
